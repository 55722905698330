<template>
  <ModalContainer @cancel="$emit('cancel')">
    <div
      class="bg-white overflow-hidden overflow-hidden shadow rounded-lg w-1/2"
    >
      <div class="border-b border-gray-200 px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ $t("game.create_game") }}
        </h3>
      </div>
      <Form
        @keypress.enter="createGame"
        @submit="createGame"
        v-slot="{ errors }"
      >
        <div class="px-4 py-5 sm:p-6 flex flex-row">
          <div class="flex flex-1">
            <Photo
              class="mr-6 flex-1"
              :title="$t('game.photo')"
              align="column"
              :url="training.image"
              :loading="!uploadReady"
              :show-origional="!uploadSuccesfull"
              @newImage="newImage"
            />
          </div>
          <div class="flex flex-col flex-2">
            <InputField
              :title="$t('game.name')"
              :placeholder="$t('game.name')"
              v-model="training.name"
              required-input
              rules="required|min:4|max:255"
              data-cy="gameName"
            />

            <TextArea
              :title="$t('game.description')"
              v-model="training.description"
              :placeholder="$t('game.description')"
              :rows="4"
              data-cy="gameDescription"
            />

            <InputField
              :title="$t('game.google_play_url')"
              :placeholder="$t('game.google_play_url')"
              v-model="training.google_play_url"
              rules="min:4|max:255"
              data-cy="googleplayUrl"
            />
            <InputField
              :title="$t('game.app_store_url')"
              :placeholder="$t('game.app_store_url')"
              v-model="training.app_store_url"
              rules="min:4|max:255"
              data-cy="appstoreUrl"
            />

            <ToggleButton
              :title="$t('game.active')"
              :val="training.active"
              @update="toggle"
              data-cy="gameActive"
            />
          </div>
          <!-- <Photo :title="$t('game.photo')" /> -->
        </div>
        <div class="border-t border-gray-200 px-4 py-4 sm:px-6">
          <div class="flex justify-end">
            <Button
              :title="$t('editor.save')"
              type="submit"
              button-type="primary"
              :invalid="Object.keys(errors).length !== 0"
              :loading="loading"
              data-cy="saveGame"
            />
            <Button
              class="ml-3"
              button-type="secondary"
              :title="$t('editor.cancel')"
              @clickAction="$emit('cancel')"
              data-cy="cancelGame"
            />
          </div>
        </div>
      </Form>
    </div>
  </ModalContainer>
</template>

<script>
import { mapState } from "vuex";
import ModalContainer from "../../ModalContainer";
import InputField from "../../../form/InputField";
import Photo from "../../../form/Photo";
import Button from "../../../form/Button";
import TextArea from "../../../form/TextArea";

import ToggleButton from "../../../form/ToggleButton";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
import axios from "axios";
const uploadRepository = RepositoryFactory.get("upload");

export default {
  name: "GameModal",
  components: {
    InputField,
    Button,
    TextArea,
    ModalContainer,
    ToggleButton,
    Photo,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel"],
  data() {
    return {
      training: {
        name: "",
        description: "",
        google_play_url: "",
        app_store_url: "",
        active: true,
        image: "",
      },
      uploadReady: true,
      uploadSuccesfull: false,
    };
  },
  computed: {
    ...mapState("trainingStore", ["loading"]),
  },
  methods: {
    clickOutside() {
      this.$emit("cancel");
    },
    toggle(val) {
      this.training.active = val;
    },
    newImage(newImage) {
      uploadRepository
        .createSignedUrl(newImage["name"])
        .then(async (result) => {
          await axios
            .put(result.data.data.url, newImage)
            .then(() => {
              this.training.image = result.data.data.url.split("?")[0];
              this.uploadSuccesfull = true;
            })
            .catch((err) => {
              errorHandlingAndMessage(
                err,
                this.$t(
                  "notifications.something_went_wrong_uploading_this_file",
                ),
              );
              this.uploadSuccesfull = false;
            })
            .finally(() => {
              this.uploadReady = true;
            });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_uploading_this_file"),
          );
          this.uploadSuccesfull = false;
          this.uploadReady = true;
        });
    },
    createGame() {
      this.$store
        .dispatch("trainingStore/CREATE_TRAINING", {
          training: this.training,
        })
        .then(() => {
          this.$store.dispatch("trainingStore/GET_TRAININGS");
          showNotification(
            this.$t("notifications.game_created"),
            this.$t("notifications.game_is_successfully_created"),
            "success",
          );
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_creating_the_game"),
          );
        });
    },
  },
};
</script>

<style></style>
