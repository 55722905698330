import { getOS } from "@/helpers/osCheck";

export function getDownloadLinkForPlatform(gamedata) {
  return new Promise(function (resolve, reject) {
    const app = gamedata.find((app) => app.platform.slug === getOS());
    if (app) {
      resolve(app);
    } else {
      resolve(null);
    }
  });
}

export async function getAlternativeDownloadableFiles(gamedata) {
  return new Promise(function (resolve, reject) {
    const alternativeGames = [];
    gamedata.map((app) => {
      if (app.platform_slug !== getOS()) {
        alternativeGames.push(app);
      }
    });
    resolve(alternativeGames);
  });
}

export async function formatTraining(training) {
  return {
    id: training.id,
    appUri: training.app_uri,
    flipped: false,
    imageUrl: training.media_legacy?.url,
    active: training.is_active,
    description: training.description,
    name: training.name,
    title: training.name,
    version: training.latest_version ? training.latest_version.id : null,
    versions: training.game_versions,
    app_store_url: training.app_store_url,
    google_play_url: training.google_play_url,
    onlineGameplayEnabled: training.is_online_gameplay_enabled,
    downloadableFile: training.latest_version
      ? training.latest_version.applications.length > 0
        ? await getDownloadLinkForPlatform(training.latest_version.applications)
        : null
      : null,
    downloadAvailable: training.latest_version
      ? training.latest_version.applications.length > 0 &&
        (await getDownloadLinkForPlatform(training.latest_version.applications))
      : false,
    alternativeDownloadableFiles: training.latest_version
      ? training.latest_version.applications.length > 0
        ? await getAlternativeDownloadableFiles(
            training.latest_version.applications,
          )
        : null
      : null,
  };
}
