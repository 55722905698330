<template>
  <div>
    <vue-flip
      v-click-outside="onClickOutside"
      :bind-with-me="flipped"
      v-model="flipped"
      class="mobile:mr-16"
      width="360px"
      height="360px"
    >
      <!--      <div slot="front">-->
      <template #front class="front">
        <CardFront
          logo-url="default/GameIcon.svg"
          @flip="flipped = !flipped"
          :downloadable="game.downloadableFile?.media?.url"
          :image="game.imageUrl"
          :active="game.active"
          :game="game"
          @setId="$emit('setId', game.id)"
        />
      </template>
      <!--      <div slot="back">-->
      <template #back class="back">
        <CardBack :game="game" @backFlip="flipped = !flipped" />
      </template>
    </vue-flip>

    <!--    <CardFront-->
    <!--      logo-url="default/GameIcon.svg"-->
    <!--      @flip="flipped = !flipped"-->
    <!--      :downloadable="game.downloadableFile"-->
    <!--      :image="game.imageUrl"-->
    <!--      :title="game.title"-->
    <!--      :active="game.active"-->
    <!--      :game="game"-->
    <!--      @setId="$emit('setId', game.id)"-->
    <!--    />-->

    <!--    <CardBack :game="game" @backFlip="flipped = !flipped" />-->
  </div>
</template>

<script>
import VueFlip from "vue-flip";
import CardFront from "./CardFront";
import CardBack from "./CardBack";
export default {
  name: "Card",
  components: {
    "vue-flip": VueFlip,
    CardFront,
    CardBack,
  },
  // directives: {
  //   clickOutside: vClickOutside.directive
  // },
  props: {
    game: {
      default: () => {},
      type: Object,
    },
  },
  data: () => ({
    flipped: false,
  }),
  methods: {
    onClickOutside(event) {
      if (this.flipped) {
        this.flipped = false;
      }
    },
  },
};
</script>

<style></style>
