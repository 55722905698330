// import Repository from "./Repository";
import ApiV4Repository from "@/repository/ApiV4Repository";

// const resource = "/api/v3/uploads";
const V4Resource = "/api/v4/media/upload";

export default {
  createSignedUrl(file) {
    return ApiV4Repository.post(`${V4Resource}`, {
      file_name: file,
    });
  },
};
