<template>
  <ModalContainer @cancel="cancel">
    <div class="flex flex-col w-1/2 h-auto bg-gray-50 p-4 rounded-md">
      <p
        class="flex self-start text-gray-700 font-normal text-20 pb-4 tracking-widest"
      >
        {{ $t("game.edit_game").toUpperCase() }}
      </p>
      <div class="w-full">
        <Form @submit="editGame" v-slot="{ errors }">
          <div v-if="newToEditTraining" class="flex flex-row mt-3">
            <div class="flex flex-col flex-1">
              <InputField
                id="name"
                :title="$t('game.name')"
                :placeholder="$t('game.name')"
                v-model="newToEditTraining.title"
                rules="required|min:4|max:255"
                data-cy="gameName"
              />
              <TextArea
                id="description"
                :title="$t('game.description')"
                v-model="newToEditTraining.description"
                :placeholder="$t('game.description')"
                :rows="4"
                cy="gameDescription"
              />

              <InputField
                id="play_url"
                :title="$t('game.google_play_url')"
                :placeholder="$t('game.google_play_url')"
                v-model="newToEditTraining.google_play_url"
                rules="min:4|max:255"
                data-cy="playUrl"
              />
              <InputField
                id="app_store_url"
                :title="$t('game.app_store_url')"
                :placeholder="$t('game.app_store_url')"
                v-model="newToEditTraining.app_store_url"
                rules="min:4|max:255"
                data-cy="appstoreUrl"
              />
              <ToggleButton
                :title="$t('game.active')"
                :val="newToEditTraining.active"
                @update="toggle"
                data-cy="toggleActive"
              />
            </div>
            <div class="flex flex-col flex-1 ml-6">
              <Photo
                class="mr-6 flex-1"
                :title="$t('game.photo')"
                align="column"
                :url="imageUrl"
                :loading="!uploadReady"
                :show-origional="!uploadSuccessful"
                @newImage="newImage"
              />
              <div
                v-if="superAdminRole && newToEditTraining.oauth_client"
                class="flex flex-1 flex-col mr-6"
              >
                <div
                  class="block text-sm font-medium leading-5 text-gray-700 border-b border-gray-200 mb-1"
                >
                  <p>
                    {{ `${$t("game.client_id")}:` }}
                  </p>
                  <p class="py-1">
                    {{ newToEditTraining.oauth_client.id }}
                  </p>
                </div>
                <div
                  class="block text-sm font-medium leading-5 text-gray-700 border-b border-gray-200 mb-1"
                >
                  <p>
                    {{ `${$t("game.secret")}:` }}
                  </p>
                  <p class="py-1 break-all">
                    {{ newToEditTraining.oauth_client.secret }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="border-t border-gray-200 px-4 py-4 sm:px-6">
            <div class="flex justify-end">
              <Button
                type="submit"
                button-type="primary"
                :title="$t('editor.save')"
                :invalid="Object.keys(errors).length !== 0"
                :loading="loading"
                data-cy="saveEdit"
              />
              <Button
                class="ml-3"
                type="button"
                button-type="secondary"
                :title="$t('editor.cancel')"
                @clickAction="cancel"
                data-cy="cancelEdit"
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  </ModalContainer>
</template>

<script>
import { mapState } from "vuex";
import ModalContainer from "../../ModalContainer";
import InputField from "../../../form/InputField";
import Photo from "../../../form/Photo";
import Button from "../../../form/Button";
import TextArea from "../../../form/TextArea";
import ToggleButton from "../../../form/ToggleButton";
import { showNotification } from "@/services/notificationService";
import { errorHandlingAndMessage } from "@/services/ErrorService";
import { superAdminRole } from "@/config";
import { RepositoryFactory } from "@/repository/RepositoryFactory";
const uploadRepository = RepositoryFactory.get("upload");
import axios from "axios";

export default {
  name: "GameModal",
  components: {
    InputField,
    Button,
    TextArea,
    ModalContainer,
    ToggleButton,
    Photo,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["cancel"],
  data() {
    return {
      imageUrl: "",
      uploadReady: true,
      uploadSuccessful: false,
      newToEditTraining: null,
    };
  },
  computed: {
    ...mapState("trainingStore", ["toEditTraining", "loading"]),
    ...mapState("userStore", ["currentUser"]),
    superAdminRole: function () {
      return this.currentUser.roles.includes(superAdminRole);
    },
  },
  mounted() {
    this.newToEditTraining = { ...this.toEditTraining };
    this.imageUrl = this.newToEditTraining.imageUrl;
  },
  methods: {
    clickOutside() {
      this.$emit("cancel");
    },
    cancel() {
      this.$emit("cancel");
    },
    toggle() {
      this.newToEditTraining.active = !this.newToEditTraining.active;
    },
    newImage(newImage) {
      this.uploadReady = false;
      uploadRepository
        .createSignedUrl(newImage["name"])
        .then(async (result) => {
          await axios
            .put(result.data.data.url, newImage)
            .then(() => {
              this.imageUrl = result.data.data.url.split("?")[0];
              this.uploadSuccessful = true;
            })
            .catch((err) => {
              errorHandlingAndMessage(
                err,
                this.$t(
                  "notifications.something_went_wrong_uploading_this_file",
                ),
              );
              this.uploadSuccessful = false;
            })
            .finally(() => {
              this.uploadReady = true;
            });
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_uploading_this_file"),
          );
          this.uploadSuccessful = false;
          this.uploadReady = true;
        });
    },

    editGame() {
      this.$store
        .dispatch("trainingStore/EDIT_TRAINING", {
          training: {
            name: this.newToEditTraining.title,
            description: this.newToEditTraining.description,
            active: this.newToEditTraining.active,
            google_play_url: this.newToEditTraining.google_play_url,
            app_store_url: this.newToEditTraining.app_store_url,
            image: this.imageUrl,
          },
          id: this.toEditTraining.id,
        })
        .then(() => {
          this.$store.dispatch("trainingStore/GET_TRAININGS");
          showNotification(
            this.$t("notifications.game_edited"),
            this.$t("notifications.game_is_successfully_edited"),
            "success",
          );
        })
        .catch((err) => {
          errorHandlingAndMessage(
            err,
            this.$t("notifications.something_went_wrong_editing_the_game"),
          );
        });
    },
  },
};
</script>
