import Repository from "./Repository";
import ApiV4Repository from "@/repository/ApiV4Repository";

const resource = "/api/v3/media";
const V4Resource = "/api/v4/media";

export default {
  getMedia({ category, type, query, page, maxResult }) {
    return ApiV4Repository.get(
      `${V4Resource}?filter[category]=${category}&filter[type]=${type}&filter[search]=${query}&page=${page}&max_results=${maxResult}`,
    );
  },
};
