<template>
  <li
    class="flex flex-row h-32 justify-start items-center text-center overflow-hidden shadow rounded-sm cursor-pointer p-2"
    :class="[
      selected === mediaItem.id
        ? 'bg-blue-400 text-white hover:bg-blue-400'
        : 'text-gray-700 hover:text-white hover:bg-blue-400',
    ]"
    @click="showMedia"
  >
    <div class="w-1/3">
      <img
        v-if="mediaType === 'image'"
        class="object-contain h-auto w-full"
        :src="mediaItem.url"
        alt=""
      />
      <img
        v-if="mediaType === 'video'"
        class="object-contain h-auto w-full"
        src="https://cdn.ndtv.com/tech/images/gadgets/oculus_leap_mount.jpg"
        alt=""
      />

      <div
        v-if="mediaType === 'audio'"
        class="flex justify-center items-center"
      >
        <font-awesome-icon
          icon="file-audio"
          class="text-29 flex items-center"
        />
      </div>
    </div>

    <div class="flex flex-col w-2/3 px-4">
      <div class="flex items-start">
        <div class="text-sm leading-5 font-light break-all text-left">
          {{ mediaItem.name }}
        </div>
      </div>
      <div class="flex items-center mt-1">
        <div class="text-xs leading-5 font-bold">
          {{ formatBytes(mediaItem.size) }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import formatBytes from "@/mixins/formatBytes";

export default {
  name: "MediaItem",
  mixins: [formatBytes],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    mediaItem: {
      type: Object,
      required: true,
    },
    mediaType: {
      type: String,
      default: "Image",
    },
    selected: {
      type: Number,
      default: null,
    },
  },
  methods: {
    showMedia() {
      this.$emit("showMedia", this.mediaItem);
    },
  },
};
</script>

<style></style>
