import Repository from "./Repository";
import ApiV4Repository from "@/repository/ApiV4Repository";

const resource = "/api/v3/trainings";
const v4_resource = "/api/v4/games";

export default {
  getTrainings() {
    return ApiV4Repository.get(
      `${v4_resource}?include=gameVersions,mediaLegacy,latestVersion`,
    );
  },

  showVersionContent(trainingId, versionId) {
    return Repository.get(
      `${resource}/${trainingId}/version/${versionId}/content?include=latest_version.applications.platform`,
    );
  },

  showVersions(trainingId) {
    return Repository.get(`${resource}/${trainingId}/versions`);
  },

  syncStatus(trainingId, versionId, runNext) {
    let urlParam = "";
    if (runNext) {
      urlParam = "?run_next=true";
    }
    return Repository.get(
      `${resource}/${trainingId}/versions/${versionId}/syncstatus${urlParam}`,
    );
  },

  updateVersion(trainingId, versionId, plaformSlug, url) {
    return Repository.patch(
      `${resource}/${trainingId}/versions/${versionId}/${plaformSlug}`,
      url,
    );
  },

  deleteVersion(trainingId, versionId) {
    return Repository.delete(`${resource}/${trainingId}/versions/${versionId}`);
  },

  deleteBuild(trainingId, versionId, slug) {
    return Repository.delete(
      `${resource}/${trainingId}/versions/${versionId}/${slug}`,
    );
  },

  getPlatforms() {
    return Repository.get(`/api/v3/platforms`);
  },

  newChapter(trainingId, versionId, chapter) {
    return Repository.post(
      `${resource}/${trainingId}/version/${versionId}/chapters`,
      { chapter },
    );
  },

  editChapter(trainingId, versionId, id, chapter) {
    return Repository.patch(
      `${resource}/${trainingId}/version/${versionId}/chapters/${id}`,
      { chapter },
    );
  },

  deleteChapter(trainingId, versionId, id) {
    return Repository.delete(
      `${resource}/${trainingId}/version/${versionId}/chapters/${id}`,
    );
  },

  createTraining(training) {
    return ApiV4Repository.post(`${v4_resource}`, training);
  },

  editTraining(training, trainingId) {
    return ApiV4Repository.patch(`${v4_resource}/${trainingId}`, training);
  },

  deleteTraining(trainingId) {
    return ApiV4Repository.delete(`${v4_resource}/${trainingId}`);
  },

  editLevel(trainingId, versionId, id, lesson) {
    return Repository.patch(
      `${resource}/${trainingId}/version/${versionId}/lessons/${id}`,
      { lesson },
    );
  },

  getLessonById(trainingId, versionId, lessonId) {
    return Repository.get(
      `${resource}/${trainingId}/version/${versionId}/lessons/${lessonId}`,
    );
  },

  createLesson(trainingId, versionId, lesson) {
    return Repository.post(
      `${resource}/${trainingId}/version/${versionId}/lessons`,
      { lesson },
    );
  },

  updateLesson(trainingId, versionId, lessonId, lesson) {
    return Repository.patch(
      `${resource}/${trainingId}/version/${versionId}/lessons/${lessonId}`,
      { lesson: lesson },
    );
  },

  deleteLesson(trainingId, versionId, lessonId) {
    return Repository.delete(
      `${resource}/${trainingId}/version/${versionId}/lessons/${lessonId}`,
    );
  },

  getTrophies(trainingId) {
    return ApiV4Repository.get(`${v4_resource}/${trainingId}/trophies`);
  },

  createTrophies(trainingId, trophy) {
    return ApiV4Repository.post(`${v4_resource}/${trainingId}/trophies`, {
      name: trophy.name,
      description: trophy.description,
    });
  },

  updateTrophies(trainingId, trophyId, trophy) {
    return ApiV4Repository.patch(
      `${v4_resource}/${trainingId}/trophies/${trophyId}`,
      {
        name: trophy.name,
        description: trophy.description,
      },
    );
  },

  deleteTrophies(trainingId, trophyId) {
    return ApiV4Repository.delete(
      `${v4_resource}/${trainingId}/trophies/${trophyId}`,
    );
  },

  publishTraining(trainingId, versionId) {
    return Repository.patch(
      `${resource}/${trainingId}/versions/${versionId}/publish`,
    );
  },

  importLesson(trainingId, versionId, subchapterId, payload) {
    return Repository.post(
      `${resource}/${trainingId}/version/${versionId}/chapters/${subchapterId}/import-lesson`,
      payload,
    );
  },
};
